import { CheckIcon } from "@radix-ui/react-icons";
import MiniSearch from "minisearch";
import type { ComponentType, KeyboardEvent } from "react";
import { useMemo, useState } from "react";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../shadcn/ShadCommand.js";
import { Popover, PopoverContent, PopoverTrigger } from "../../shadcn/ShadPopover.js";
import cn from "../../util/cn.js";
import FlexibleSpacer from "../FlexibleSpacer/FlexibleSpacer.js";
import HStack from "../Stack/HStack.js";
import Label from "../Typography/Label.js";
import type ComboboxOption from "./models/ComboboxOption.js";
import type TriggerProps from "./models/TriggerProps.js";
import RowCommandItem from "./RowCommandItem.js";

export interface BaseComboboxProps<T> {
  placeholder?: string;
  inputClassName?: string;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onChange: (options: Array<ComboboxOption<T>>) => void;
  selectedOptions: Array<ComboboxOption<T>>;
  disabled?: boolean;
  optionsLoading?: boolean;
  options?: Array<ComboboxOption<T>>;
  align?: "start" | "end";
  multiSelect?: boolean;
  hasMore?: boolean;

  /**
   * Allows the user to clear the selection
   */
  allowClear?: boolean;

  /**
   * Show descrpitions inlined with the options
   */
  inlineDescription?: boolean;

  /**
   * Custom label for clear selection. Will default to the placeholder if not provided.
   */
  clearSelectLabel?: string;

  triggerClassName?: string;
  triggerIcon?: React.ReactNode;
  dropdownClassName?: string;

  trigger: ComponentType<TriggerProps<T>>;

  /**
   * Optionally turn query into a controlled input to be support custom filtering and
   * async loading of options. If passed in that ALL options are always displayed. Both
   * query and onQueryChange must be passed in together.
   */
  query?: string;
  onQueryChange?: (query: string) => void;

  /**
   * Functions that compars two values and returns true if they are equal. You should
   * pass this in if the values are complex and the options load asynchronously.
   */
  valuesEqual?: (value1: T, value2: T) => boolean;
}

const BaseCombobox = <T,>({
  selectedOptions,
  placeholder,
  allowClear,
  clearSelectLabel,
  options,
  multiSelect,
  triggerClassName,
  triggerIcon,
  dropdownClassName,
  align = "start",
  inlineDescription,
  onChange,
  disabled,
  optionsLoading,
  trigger,
  hasMore,
  valuesEqual,
  ...props
}: BaseComboboxProps<T>) => {
  const [localQuery, setLocalQuery] = useState("");
  const [isShowingPopover, setIsShowingPopover] = useState(false);

  const hasIcons = options?.some((o) => o.icon);
  const hasDescriptions = options?.some((o) => o.description);
  const hasAccessories = hasIcons || hasDescriptions;

  const localSearcher: MiniSearch<ComboboxOption<T>> = useMemo(() => {
    const searcher = new MiniSearch<ComboboxOption<T>>({
      idField: "value",
      fields: ["label", "description"],
      storeFields: [
        "label",
        "description",
        "icon",
        "value",
        "key",
        "disabled",
        "isLoading",
        "clearable",
      ],
      searchOptions: {
        prefix: true,
        fuzzy: 2,
        boost: {
          label: 2,
        },
      },
    });
    if (options) {
      searcher.addAll(options);
    }
    return searcher;
  }, [options]);

  const filteredOptions = useMemo(() => {
    // If we are using a controlled query, it's up to the parent to filter the options
    if (props.onQueryChange) {
      return options;
    }

    if (!localQuery) {
      return options;
    }

    return localSearcher.search(localQuery) as any as Array<ComboboxOption<T>>;
  }, [options, localQuery]);

  const Trigger = trigger;

  return (
    <Popover open={isShowingPopover} onOpenChange={setIsShowingPopover}>
      <PopoverTrigger
        disabled={disabled}
        asChild
        className={cn({
          "w-full": multiSelect,
        })}
      >
        <Trigger
          options={{
            allowClear,
            selectedOptions,
            triggerClassName,
            triggerIcon,
            placeholder,
            disabled,
            onRemoveValue: (value) => {
              onChange(
                selectedOptions.filter((option) =>
                  valuesEqual ? !valuesEqual(option.value, value) : option.value !== value
                )
              );
            },
            onClearAll: () => {
              onChange(selectedOptions.filter((o) => o.clearable === false));
            },
          }}
        />
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          "p-0 w-screen",
          {
            "max-w-[300px]": hasAccessories,
            "max-w-[200px]": !hasAccessories,
          },
          dropdownClassName
        )}
        align={align}
      >
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search..."
            value={props.onQueryChange ? props.query : localQuery}
            onValueChange={(e) => (props.onQueryChange ? props.onQueryChange(e) : setLocalQuery(e))}
            loading={optionsLoading}
          />
          <CommandList>
            <CommandEmpty>{optionsLoading ? "Loading..." : "No results found."}</CommandEmpty>
            <CommandGroup>
              {filteredOptions && allowClear && (
                <CommandItem
                  onSelect={() => {
                    onChange([]);
                    setIsShowingPopover(false);
                  }}
                >
                  <HStack className="w-full">
                    {hasIcons && <div className="w-6" />}
                    <div>{clearSelectLabel ?? "None"}</div>
                    <FlexibleSpacer />
                    <CheckIcon
                      className={cn("w-6 flex-none text-accent", {
                        "opacity-100": !selectedOptions.length,
                        "opacity-0": !!selectedOptions.length,
                      })}
                    />
                  </HStack>
                </CommandItem>
              )}
              {filteredOptions?.map((option) => {
                return (
                  <RowCommandItem
                    {...option}
                    key={option.key ?? option.value?.toString() ?? ""}
                    value={option.key || option.value?.toString() || "___"}
                    hasIcons={hasIcons}
                    inlineDescription={inlineDescription}
                    isSelected={selectedOptions.some((o) =>
                      valuesEqual ? valuesEqual(o.value, option.value) : o.value === option.value
                    )}
                    onSelect={() => {
                      if (multiSelect) {
                        const selectedValue = option.value;
                        const newValues = selectedOptions.some((o) =>
                          valuesEqual
                            ? valuesEqual(o.value, selectedValue)
                            : o.value === selectedValue
                        )
                          ? selectedOptions.filter((o) => o.value !== selectedValue)
                          : [...selectedOptions, option];

                        onChange(newValues);
                      } else {
                        onChange([option]);
                        setIsShowingPopover(false);
                      }
                    }}
                  />
                );
              })}
            </CommandGroup>
            {!optionsLoading && hasMore && (
              <Label intent="tertiary" maxLines={2} className="p-2 text-center">
                Search to find more options
              </Label>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default BaseCombobox;
