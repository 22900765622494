import { CheckIcon } from "@radix-ui/react-icons";
import type { ReactNode } from "react";

import { CommandItem } from "../../shadcn/ShadCommand.js";
import cn from "../../util/cn.js";
import FlexibleSpacer from "../FlexibleSpacer/FlexibleSpacer.js";
import HStack from "../Stack/HStack.js";

interface Props {
  isSelected: boolean;
  onSelect: () => void;
  hasIcons?: boolean;
  label?: string | null;
  description?: string | null;
  icon?: ReactNode;
  inlineDescription?: boolean;
  disabled?: boolean;
  value?: string;
}

const RowCommandItem = ({
  label,
  description,
  icon,
  inlineDescription,
  disabled,
  hasIcons,
  isSelected,
  onSelect,
  value,
}: Props) => {
  return (
    <CommandItem onSelect={onSelect} disabled={disabled} value={value}>
      <HStack
        className={cn("w-full", {
          "opacity-80": disabled,
        })}
      >
        {hasIcons && <div className="w-6 flex-none flex items-center justify-center">{icon}</div>}
        <div
          className={cn("flex", {
            "flex-col": !inlineDescription,
            "flex-row items-center gap-2": inlineDescription,
          })}
        >
          <div>{label}</div>
          {description && <div className="text-sm text-tertiary">{description}</div>}
        </div>
        <FlexibleSpacer />
        <CheckIcon
          className={cn("w-6 flex-none text-accent", {
            "opacity-100": isSelected,
            "opacity-0": !isSelected,
          })}
        />
      </HStack>
    </CommandItem>
  );
};

export default RowCommandItem;
